const menu = [
  {
    category: true,
    title: 'Menu',
  },
  {
    title: 'Dashboard',
    key: 'dashboard',
    icon: 'fe fe-home',
    // roles: ['admin'], // set user roles with access to this route
    url: '/dashboard',
  },
  {
    title: 'Pengajuan Rubrik',
    key: 'pengajuanRubrik',
    icon: 'fe fe-clipboard',
    url: '/pengajuan-rubrik',
  },
]

export default menu
