import axios from 'axios'
import { notification } from 'antd'
import authHeader from 'helpers/authHeader'
import { store } from 'index'

const {
  REACT_APP_NODE_ENV,
  REACT_APP_SIREMUN_PROD_URL,
  REACT_APP_SIREMUN_DEV_URL,
  REACT_APP_JABATAN_PROD_URL,
  REACT_APP_JABATAN_DEV_URL,
} = process.env

export async function getPeriodeAktif() {
  if (REACT_APP_NODE_ENV === 'development') {
    try {
      const res = await axios.get(`${REACT_APP_SIREMUN_DEV_URL}/periode/list-aktif`, {
        ...authHeader(),
      })
      return res.data.data
    } catch {
      return false
    }
  } else {
    try {
      const res = await axios.get(`${REACT_APP_SIREMUN_PROD_URL}/periode/list-aktif`, {
        ...authHeader(),
      })
      return res.data.data
    } catch {
      return false
    }
  }
}

export async function getAllPegawai() {
  if (REACT_APP_NODE_ENV === 'development') {
    try {
      const user = await store.getState().user.userInfo
      const nip = user?.nip
      const res = await axios.get(
        `${REACT_APP_SIREMUN_DEV_URL}/verifikator/list-pegawai?nip=${nip}`,
        {
          ...authHeader(),
        },
      )
      return res.data.data
    } catch {
      return false
    }
  } else {
    try {
      const user = await store.getState().user.userInfo
      const nip = user?.nip
      const res = await axios.get(
        `${REACT_APP_SIREMUN_PROD_URL}/verifikator/list-pegawai?nip=${nip}`,
        {
          ...authHeader(),
        },
      )
      return res.data.data
    } catch {
      return false
    }
  }
}
export async function setPengajuan(data) {
  if (REACT_APP_NODE_ENV === 'development') {
    try {
      const res = await axios.post(`${REACT_APP_SIREMUN_DEV_URL}/pengajuan/verifikasi`, data, {
        ...authHeader(),
      })
      notification.success({
        message: 'Berhasil',
        description: res.data?.message,
      })

      return res.data
    } catch {
      notification.error({
        message: 'Error',
        description: `Service Siremun Sedang Bermasalah`,
      })
      return false
    }
  } else {
    try {
      const res = await axios.post(`${REACT_APP_SIREMUN_PROD_URL}/pengajuan/verifikasi`, data, {
        ...authHeader(),
      })
      notification.success({
        message: 'Berhasil',
        description: res.data?.message,
      })

      return res.data
    } catch {
      notification.error({
        message: 'Error',
        description: `Service Siremun Sedang Bermasalah`,
      })
      return false
    }
  }
}

export async function getAllPengajuan(nip) {
  if (REACT_APP_NODE_ENV === 'development') {
    try {
      const res = await axios.get(
        `${REACT_APP_SIREMUN_DEV_URL}/v2/verifikator/pengajuan?nip=${nip}`,
        {
          ...authHeader(),
        },
      )
      return res.data.data
    } catch {
      notification.error({
        message: 'Error',
        description: `Service Siremun Sedang Bermasalah`,
      })
      return false
    }
  } else {
    try {
      const res = await axios.get(
        `${REACT_APP_SIREMUN_PROD_URL}/v2/verifikator/pengajuan?nip=${nip}`,
        {
          ...authHeader(),
        },
      )
      return res.data.data
    } catch {
      notification.error({
        message: 'Error',
        description: `Service Siremun Sedang Bermasalah`,
      })
      return false
    }
  }
}

export async function getUserInfo(nip) {
  if (REACT_APP_NODE_ENV === 'development') {
    try {
      const res = await axios.get(`${REACT_APP_JABATAN_DEV_URL}/pegawai?nip=${nip}`)
      return res.data.data
    } catch {
      notification.error({
        message: 'Error',
        description: `Terjadi Kesalahan Pada Service Jabatan`,
      })
      return false
    }
  } else {
    try {
      const res = await axios.get(`${REACT_APP_JABATAN_PROD_URL}/pegawai?nip=${nip}`)
      return res.data.data
    } catch {
      notification.error({
        message: 'Error',
        description: `Terjadi Kesalahan Pada Service Jabatan`,
      })
      return false
    }
  }
}
