const actions = {
  SET_STATE: 'detailPengajuan/SET_STATE',
  SAVE_DATA: 'detailPengajuan/SAVE_DATA',
  GET_DATA: 'detailPengajuan/GET_DATA',
  DELETE_DATA: 'detailPengajuan/DELETE_DATA',
  GET_USER_INFO: 'detailPengajuan/GET_USER_INFO',
  VERIFIKASI_PENGAJUAN: 'detailPengajuan/VERIFIKASI_PENGAJUAN',
}

export default actions
