const actions = {
  SET_STATE: 'pengajuan/SET_STATE',
  SAVE_DATA: 'pengajuan/SAVE_DATA',
  GET_DATA: 'pengajuan/GET_DATA',
  DELETE_DATA: 'pengajuan/DELETE_DATA',
  SET_PENGAJUAN: 'pengajuan/SET_PENGAJUAN',
  GET_DETAIL: 'pengajuan/GET_DETAIL',
}

export default actions
