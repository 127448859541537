/* eslint-disable */
import { all, takeEvery, put, call, select, take } from 'redux-saga/effects'
import { notification } from 'antd'
import { history } from 'index'
import { login, userInfoByToken, checkIsVerifikator } from 'services/login'
import * as firebase from 'services/firebase'
import * as jwt from 'services/jwt'
import actions from './actions'
import { REHYDRATE } from 'redux-persist/lib/constants'

const mapAuthProviders = {
  firebase: {
    login: firebase.login,
    register: firebase.register,
    currentAccount: firebase.currentAccount,
    logout: firebase.logout,
  },
  jwt: {
    login: jwt.login,
    register: jwt.register,
    currentAccount: jwt.currentAccount,
    logout: jwt.logout,
  },
}

export function* LOGIN({ payload }) {
  const { email, password } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  // const { authProvider: autProviderName } = yield select(state => state.settings)
  const response = yield call(login, email, password)
  // console.log(response)
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
  if (response.status === 'error') {
    notification.error({
      message: response.message,
    })
  }
  if (response.status === 'success') {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        userInfo: response.user,
        token: response.token,
        authorized: true,
      },
    })
    yield put({
      type: 'user/LOAD_CURRENT_ACCOUNT',
    })
    yield history.push('/dashboard')
    notification.success({
      message: 'Login Berhasil',
    })
  }
}

export function* LOAD_CURRENT_ACCOUNT() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const getItems = state => state.user
  const project = yield select(getItems)
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* LOGIN_BY_TOKEN({ payload }) {
  const { token, nip } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(userInfoByToken, token, nip)
  if (response === false) {
    notification.error({
      message: 'Akses Ditolak',
    })
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
        userInfo: {},
      },
    })
    yield history.push('/auth/login')
  }

  if (response.status === 'success') {
    const responseCheckIsVerifikator = yield call(checkIsVerifikator, response.data?.nip)
    const totalBawahan = responseCheckIsVerifikator.data.bawahan.length

    if (totalBawahan === 0) {
      notification.error({
        message: 'Akses Ditolak',
      })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
          userInfo: {},
        },
      })
      yield history.push('/auth/login')
    } else {
      notification.success({
        message: 'Login Berhasil',
      })

      yield put({
        type: 'user/SET_STATE',
        payload: {
          userInfo: response.data,
          token: payload.token,
          authorized: true,
        },
      })

      yield put({
        type: 'user/LOAD_CURRENT_ACCOUNT',
      })
      yield history.push('/')
    }
  } else {
    notification.error({
      message: 'Akses Ditolak',
    })
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
        userInfo: {},
      },
    })
    yield history.push('/auth/login')
  }

  // if (response.status === 'error') {
  //   notification.error({
  //     message: response.message,
  //   })
  // }
  // if (response.status === 'success') {
  //   yield put({
  //     type: 'user/SET_STATE',
  //     payload: {
  //       userInfo: response,
  //       authorized: true,
  //     },
  //   })
  //   yield put({
  //     type: 'user/LOAD_CURRENT_ACCOUNT',
  //   })
  //   yield history.push('/dashboard')
  //   notification.success({
  //     message: 'Login Berhasil',
  //   })
  // }
}

export function* LOGOUT() {
  const { authProvider } = yield select(state => state.settings)
  yield call(mapAuthProviders[authProvider].logout)
  yield put({
    type: 'user/SET_STATE',
    payload: {
      authorized: false,
      loading: false,
      token: '',
      userInfo: {},
    },
  })
  yield put({
    type: 'detailPengajuan/SET_STATE',
    payload: {
      loading: false,
      listAll: [],
      isListAllError: false,
      userInfo: {},
      isUserInfoError: false,
      responseSubmitPengajuan: {},
    },
  })
  yield put({
    type: 'pengajuan/SET_STATE',
    payload: {
      listAll: [],
      loading: false,
      periodeAktif: {},
    },
  })
}

export default function* rootSaga() {
  // console.log('Waiting for rehydration')
  yield take(REHYDRATE) // Wait for rehydrate to prevent sagas from running with empty store
  // console.log('Rehydrated')
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.LOGIN_BY_TOKEN, LOGIN_BY_TOKEN),
    takeEvery(actions.LOGOUT, LOGOUT),
    LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
  ])
}
