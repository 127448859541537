import apiClient from 'services/axios'
import axios from 'axios'
import store from 'store'
import { notification } from 'antd'
// import { connect } from 'react-redux'

const mapStateToProps = ({ user }) => ({
  user,
})

const {
  REACT_APP_NODE_ENV,
  REACT_APP_SIREMUN_PROD_URL,
  REACT_APP_SIREMUN_DEV_URL,
  REACT_APP_JABATAN_PROD_URL,
  REACT_APP_JABATAN_DEV_URL,
} = process.env

export async function login(email, password) {
  if (REACT_APP_NODE_ENV === 'development') {
    return apiClient
      .post(`${REACT_APP_SIREMUN_DEV_URL}/login-verifikator`, {
        username: email,
        password,
      })
      .then(response => {
        // console.log(response)
        if (response) {
          // const { accessToken } = response.data
          // if (accessToken) {
          //   store.set('accessToken', accessToken)
          // }
          return response.data
        }
        return false
      })
      .catch(err => {
        notification.error({
          message: 'service unavailable',
        })
        console.log({ err })
      })
  }
  return apiClient
    .post(`${REACT_APP_SIREMUN_PROD_URL}/login-verifikator`, {
      username: email,
      password,
    })
    .then(response => {
      // console.log(response)
      if (response) {
        // const { accessToken } = response.data
        // if (accessToken) {
        //   store.set('accessToken', accessToken)
        // }
        return response.data
      }
      return false
    })
    .catch(err => {
      notification.error({
        message: 'service unavailable',
      })
      console.log({ err })
    })
}

export async function currentAccount() {
  // console.log(user)
  console.log(mapStateToProps.user)
}
// connect(mapStateToProps)(currentAccount)

export async function logout() {
  return apiClient
    .get('/auth/logout')
    .then(() => {
      store.remove('accessToken')
      return true
    })
    .catch(err => console.log(err))
}

export async function userInfoByToken(token, nip) {
  if (REACT_APP_NODE_ENV === 'development') {
    try {
      const res = await axios.get(`${REACT_APP_SIREMUN_DEV_URL}/user-info?nip=${nip}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      return res.data
    } catch (err) {
      return false
    }
  } else {
    try {
      const res = await axios.get(`${REACT_APP_SIREMUN_DEV_URL}/user-info?nip=${nip}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      return res.data
    } catch (err) {
      return false
    }
  }
}

export async function checkIsVerifikator(nip) {
  if (REACT_APP_NODE_ENV === 'development') {
    try {
      const res = await axios.get(`${REACT_APP_JABATAN_DEV_URL}/bawahan?nip=${nip}`)
      return res.data
    } catch (error) {
      return false
    }
  } else {
    try {
      const res = await axios.get(`${REACT_APP_JABATAN_PROD_URL}/bawahan?nip=${nip}`)
      return res.data
    } catch (error) {
      return false
    }
  }
}
