import React from 'react'
import style from './style.module.scss'

const Footer = () => {
  const date = new Date()
  const year = date.getFullYear()
  return (
    <div className={style.footer}>
      <div className={style.footerInner}>
        <p className="mb-0">
          © {year}{' '}
          <span className="font-weight-bold blue">UPT. Teknologi Informasi dan Komunikasi</span>{' '}
          Universitas Tanjungpura
        </p>
      </div>
    </div>
  )
}

export default Footer
