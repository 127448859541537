import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import { connectRouter } from 'connected-react-router'
import storage from 'redux-persist/es/storage'
import userReducer from './user/reducers'
import settingsReducers from './settings/reducers'
import pengajuanReducer from './pengajuan/reducers'
import detailPengajuanReducer from './detailPengajuan/reducers'

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['router'],
}

const rootReducers = history =>
  combineReducers({
    router: connectRouter(history),
    settings: settingsReducers,
    user: userReducer,
    pengajuan: pengajuanReducer,
    detailPengajuan: detailPengajuanReducer,
  })

export default history => persistReducer(persistConfig, rootReducers(history))
