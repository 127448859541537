import { store } from 'index'

export default function authHeader() {
  const token = store.getState().user?.token ?? ''
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
}
