/* eslint-disable */
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import './style/verifikasi.css'

const mapStateToProps = ({ router, dispatch }) => ({
  dispatch,
  routerPath: router.location.pathname,
})

const VerifyToken = ({ routerPath, dispatch }) => {
  const splitPath = routerPath.split('/')
  const token = splitPath[3]
  const nip = splitPath[4]

  useEffect(() => {
    loginByToken()
  }, [])

  const loginByToken = () => {
    dispatch({
      type: 'user/SET_STATE',
      payload: {
        userInfo: {},
        token: '',
        authorized: false,
        loading: false,
      },
    })

    dispatch({
      type: 'user/LOGIN_BY_TOKEN',
      payload: {
        loading: false,
        token,
        nip,
      },
    })
  }

  return (
    <div>
      <Helmet title={`Verify Token`} />
      <div className="vh-100 verifikasi-page">
        <div id="logo">
          <div className="d-flex flex-row">
            <div className="d-flex flex-column">
              <img src="/resources/images/untan.png" width="50px" alt="logo" />
            </div>
            <div className="d-flex flex-column ml-2">
              <p className="mb-0 font-weight-bold">Universitas Tanjungpura</p>
              <p className="mb-0">Kalimantan Barat</p>
            </div>
          </div>
        </div>
        <div className="container-fluid h-100">
          <div className="row h-100">
            <div className="col-md-6 h-100 d-flex align-items-center justify-content-center animated fadeIn">
              <div className="d-flex flex-column content text-md-start title">
                <p className="mb-0">Please wait,</p>
                <h1 className="font-weight-bold mb-0">Verifying Access</h1>
              </div>
            </div>
            <div className="col-md-6 h-100 d-none d-md-flex justify-content-center align-items-center animated fadeIn">
              <img src="/resources/verifikasi/verifikasi.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="text-center">
        <p className="custom-footer">
          © 2021 <span className="font-weight-bold">UPT. Teknologi Informasi dan Komunikasi</span>{' '}
          Universitas Tanjungpura
        </p>
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(VerifyToken)
