import { all, put, call, takeEvery } from 'redux-saga/effects'
import { getAllPegawai, setPengajuan, getPeriodeAktif } from 'services/pengajuan'
import { notification } from 'antd'
import actions from './actions'

export function* GET_DATA() {
  yield put({
    type: 'pengajuan/SET_STATE',
    payload: {
      listAll: [],
      loading: true,
    },
  })

  const response = yield call(getAllPegawai)

  if (!response) {
    yield put({
      type: 'pengajuan/SET_STATE',
      payload: {
        listAll: [],
        loading: false,
      },
    })
    notification.error({
      message: 'Error',
      description: `Service Unavailable`,
    })
  }

  if (response) {
    yield put({
      type: 'pengajuan/SET_STATE',
      payload: {
        listAll: response,
        loading: false,
      },
    })

    const responsePeriode = yield call(getPeriodeAktif)
    if (responsePeriode) {
      yield put({
        type: 'pengajuan/SET_STATE',
        payload: {
          periodeAktif: responsePeriode,
        },
      })
    }
  }
}

export function* SET_PENGAJUAN({ payload }) {
  const response = yield call(setPengajuan, payload)
  if (response) {
    yield put({
      type: 'pengajuan/SET_STATE',
    })
    yield put({
      type: 'pengajuan/GET_DATA',
      payload: {
        resultSetPengajuan: response,
        loading: false,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_DATA, GET_DATA),
    takeEvery(actions.SET_PENGAJUAN, SET_PENGAJUAN),
  ])
}
