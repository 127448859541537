import { all, put, call, takeEvery } from 'redux-saga/effects'
// import getData from 'services/example'
import { getUserInfo, getAllPengajuan, setPengajuan } from 'services/pengajuan'
import { history } from 'index'
import { notification } from 'antd'
import actions from './actions'

export function* GET_DATA({ payload }) {
  const responseUserInfo = yield call(getUserInfo, payload.nip)
  yield put({
    type: 'detailPengajuan/SET_STATE',
    payload: {
      listAll: [],
      isListAllError: false,
      userInfo: {},
      isUserInfoError: false,
      responseSubmitPengajuan: {},
    },
  })
  if (responseUserInfo) {
    const atasanUserID = responseUserInfo.atasan_id
    if (payload.verifikatorID !== atasanUserID) {
      history.push('/pengajuan-rubrik')
      notification.error({
        message: 'Tidak Diizinkan',
        description: `Anda Tidak Memiliki Akses Untuk Memverifikasi Dosen Ini`,
      })
    } else {
      yield put({
        type: 'detailPengajuan/SET_STATE',
        payload: {
          userInfo: responseUserInfo,
          loading: false,
          isUserInfoError: false,
        },
      })
    }
  } else {
    yield put({
      type: 'detailPengajuan/SET_STATE',
      payload: {
        loading: false,
        isUserInfoError: true,
      },
    })
  }
  const responseListPengajuan = yield call(getAllPengajuan, payload.nip)
  if (responseListPengajuan) {
    yield put({
      type: 'detailPengajuan/SET_STATE',
      payload: {
        loading: false,
        listAll: responseListPengajuan,
        isListAllError: false,
      },
    })
  } else {
    yield put({
      type: 'detailPengajuan/SET_STATE',
      payload: {
        loading: false,
        isListAllError: true,
      },
    })
  }
}

export function* VERIFIKASI_PENGAJUAN({ payload }) {
  const response = yield call(setPengajuan, payload)
  if (response) {
    yield put({
      type: 'detailPengajuan/SET_STATE',
      payload: {
        loading: false,
        responseSubmitPengajuan: response,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_DATA, GET_DATA),
    takeEvery(actions.VERIFIKASI_PENGAJUAN, VERIFIKASI_PENGAJUAN),
  ])
}
