import React from 'react'
import { connect } from 'react-redux'
// import { UserOutlined } from '@ant-design/icons'
import { Menu, Dropdown } from 'antd'
import styles from './style.module.scss'

const mapStateToProps = ({ user }) => ({ user })
const ProfileMenu = ({ dispatch, user }) => {
  const logout = e => {
    e.preventDefault()
    dispatch({
      type: 'user/LOGOUT',
    })
  }

  function handleImageError(e) {
    e.currentTarget.src = '/resources/images/avatars/2.jpg'
  }

  const menu = (
    <Menu selectable={false}>
      <Menu.Item>
        <strong>{user?.userInfo?.name || 'User'}</strong>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item>
        <a href="#" onClick={logout}>
          <i className="fe fe-log-out mr-2" />
          Logout
        </a>
      </Menu.Item>
    </Menu>
  )
  return (
    <Dropdown overlay={menu}>
      <div className={styles.dropdown}>
        {/* <Badge count={count}> */}
        <div className="kit__utils__avatar kit__utils__avatar--size46">
          <img
            src={`https://bkd.untan.ac.id/pub/foto-${user?.userInfo?.username}.jpg`}
            alt="Mary Stanform"
            onError={handleImageError}
          />
        </div>
      </div>
    </Dropdown>
  )
}

export default connect(mapStateToProps)(ProfileMenu)
